import {
  reservation_init,
  get_chekout,
  get_reservation_admin,
  get_reservation_client,
} from '@/services/models/reservation';

const reservation = {
  namespaced: true,
  state: {
    reservationDate: null,
    reservation: null,
    reservationAdmin: null,
    driverInfo: null,
  },

  getters: {
    reservationDate: state => state.reservationDate,
    reservation: state => state.reservation,
    reservationAdmin: state => state.reservationAdmin,
    driverInfo: state => state.driverInfo,
  },

  mutations: {
    SET_DATE(state, payload) {
      state.reservationDate = { ...payload };
    },
    SET_RESERVATION(state, payload) {
      state.reservation = { ...payload };
    },
    SET_RESERVATION_ADMIN(state, payload) {
      state.reservationAdmin = { ...payload };
    },
    SET_DRIVER_INFO(state, payload) {
      state.driverInfo = payload;
    },
  },

  actions: {
    async getReservationAdmin({ commit }, data) {
      try {
        const response = await get_reservation_admin(data);
        commit('SET_RESERVATION_ADMIN', { ...response.data });
      } catch (error) {
        console.error(error);
      }
    },

    async getReservationClient({ commit }, data) {
      try {
        const response = await get_reservation_client(data);
        commit('SET_RESERVATION', { ...response.data });
      } catch (error) {
        console.error(error);
      }
    },

    async reservationDates({ commit }, data) {
      commit('SET_DATE', data);
    },

    async set_driver_info({ commit }, data) {
      let driverInfo = null;
      if (data) {
        console.log(data, 'data');
        const allFieldsEmpty = Object.values(data).every(value => !value);
        driverInfo = allFieldsEmpty
          ? null
          : {
              full_name: data?.full_name ?? '',
              dob: data?.dob ?? '',
              cpf: data?.cpf ?? '',
              email: data?.email ?? '',
              phone: data?.phone ?? '',
            };
      } else {
        driverInfo = null;
      }
      commit('SET_DRIVER_INFO', driverInfo);
      return driverInfo;
    },

    async checkout({ commit }) {
      try {
        const response_get_checkout = await get_chekout();
        if (response_get_checkout.sucess) {
          commit('SET_RESERVATION', response_get_checkout);
          return response_get_checkout;
        } else {
          commit('SET_RESERVATION', false);
          return false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async reservationDataInit({ commit, dispatch }, data) {
      try {
        const response_init = await reservation_init(data);
        if (response_init.status === 200 && response_init.data) {
          dispatch('checkout');
        } else {
          return false;
        }
      } catch (error) {
        return error;
      }
    },
  },
};

export default reservation;
