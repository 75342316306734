import profile from '@/router/profile';
import { api } from '../api';

// Listar locadoras com paginação
const getCarRentals = async filters => {
  try {
    const response = await api.get(`/admin/car_rental/list`, {
      params: filters ,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Erro ao buscar locadoras';
  }
};

// Buscar locadora por ID
const getCarRentalById = async (id, field) => {
  try {
    const response = await api.get(`/admin/car_rental/${id}`, {
      params: { field },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Erro ao buscar locadora';
  }
};

const setSessionCompany = async id => {
  try {
    const response = await api.get(`/shared?car_rental_id=${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Erro ao buscar locadora';
  }
};

// Atualizar uma locadora existente
const updateCarRental = async (id, carRentalData, files) => {
  try {
    // Garantir que os campos JSON estejam no formato correto
    const preparedData = {
      ...carRentalData,
      address:
        typeof carRentalData.address === 'string'
          ? carRentalData.address
          : JSON.stringify(carRentalData.address || {}),
      contact:
        typeof carRentalData.contact === 'string'
          ? carRentalData.contact
          : JSON.stringify(carRentalData.contact || []),
    };

    const formData = new FormData();
    formData.append('model', JSON.stringify(preparedData));

    // Adiciona os arquivos, se houver
    files.forEach(file => {
      const fileName = carRentalData.logo || file.name;
      formData.append('uploads', file, fileName);
    });

    const response = await api.put(`/admin/car_rental/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!response.data) {
      throw new Error('Resposta inválida do servidor');
    }
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar locadora:', error);
    throw {
      success: false,
      message:
        error.response?.data?.message ||
        error.message ||
        'Erro ao atualizar locadora',
      error,
    };
  }
};

// Criar ou atualizar uma locadora
const saveCarRental = async (carRentalData, files = []) => {
  try {
    // Garantir que os campos JSON estejam no formato correto
    const preparedData = {
      ...carRentalData,
      address: JSON.stringify(carRentalData.address || {}),
      contact: JSON.stringify(carRentalData.contact || []),
    };

    const formData = new FormData();

    // Adiciona os dados da locadora
    formData.append('model', JSON.stringify(preparedData));

    // Adiciona os arquivos, se houver
    files.forEach(file => {
      const fileName = carRentalData.logo || file.name;
      formData.append('uploads', file, fileName);
    });

    const response = await api.post('/admin/car_rental', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!response.data) {
      throw new Error('Resposta inválida do servidor');
    }

    return response.data;
  } catch (error) {
    throw {
      success: false,
      message: error.response?.data?.message,
    };
  }
};

// Deletar uma locadora por ID
const deleteCarRental = async id => {
  try {
    const response = await api.delete(`/admin/car_rental/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Erro ao deletar locadora';
  }
};

export {
  getCarRentals,
  getCarRentalById,
  updateCarRental,
  saveCarRental,
  deleteCarRental,
  setSessionCompany,
};
