import { api } from '../api';

const getCities = async () => {
  try {
    const response = await api.get('/shared?city=list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getGeolocation = async () => {
  try {
    const response = await api.get('/geoLocation');
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getLocations = async () => {
  try {
    const response = await api.get('/shared?locations=list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const searchReservation = async params => {
  try {
    const response = await api.get('/reservation/list', {
      params,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const reservation_init = async payload => {
  try {
    const response = await api.post('/reservation/init', payload);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const post_chekout = async payload => {
  try {
    const response = await api.post('/reservation/checkout', payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const get_chekout = async () => {
  try {
    const response = await api.get('/reservation/checkout');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const post_reservation = async payload => {
  try {
    const response = await api.post('/reservation/create', payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const post_reservation_admin = async payload => {
  try {
    const response = await api.post('/admin/reservation/create', payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const update_reservation_admin = async (id, fields) => {
  try {
    const response = await api.put(`admin/reservation/update/${id}`, fields);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const update_reservation_client = async (id, reservation) => {
  try {
    const response = await api.put(`reservation/update/${id}`, reservation);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const get_reservations_admin = async params => {
  try {
    const response = await api.get('admin/reservation/list', {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const get_reservation_admin = async id => {
  try {
    const response = await api.get(`admin/reservation/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const get_reservation_client = async id => {
  try {
    const response = await api.get(`reservation/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const upload_driver_image = async formData => {
  try {
    const response = await api.post(
      `admin/reservation/upload_image_driver`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const delete_driver_image = async (folder, payload) => {
  try {
    const response = await api.post(`admin/reservation/delete_image_driver`, {
      ...folder,
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const update_driver = async (reservation_id, driver) => {
  try {
    const response = await api.put(
      `admin/reservation/update_driver/${reservation_id}`,
      driver
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const update_extras = async (reservation_id, extras) => {
  try {
    const response = await api.put(
      `/admin/reservation/update_extras/${reservation_id}`,
      extras
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const update_additionals = async (reservation_id, additionals) => {
  try {
    const response = await api.put(
      `/admin/reservation/update_additionals/${reservation_id}`,
      additionals
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export {
  searchReservation,
  getCities,
  getLocations,
  reservation_init,
  get_chekout,
  post_chekout,
  post_reservation,
  get_reservations_admin,
  post_reservation_admin,
  getGeolocation,
  get_reservation_admin,
  get_reservation_client,
  update_reservation_client,
  update_reservation_admin,
  upload_driver_image,
  delete_driver_image,
  update_driver,
  update_extras,
  update_additionals,
};
